import createGlobe from "./cobe.js";

let phi = 4.44;
let canvas = document.getElementById("globe");

const globe = createGlobe(canvas, {
  devicePixelRatio: 2,
  width: 444 * 2,
  height: 444 * 2,
  phi: 4.44,
  theta: 0.38,
  dark: 1,
  diffuse: 1.2,
  mapSamples: 75125,
  mapBrightness: 6.26,
  baseColor: [0.24, 0.24, 0.24],
  markerColor: [1, 1, 1],
  glowColor: [1, .87, 0],
  markers: [
    // longitude latitude
  ],
  onRender: (state) => {
    // Called on every animation frame.
    // `state` will be an empty object, return updated params.
    state.phi = phi;
    phi += 0.00333;
  }
});

const warnings = document.getElementById("warnings");
const warningsText = document.querySelector("#warnings .text")

warnings.onclick = (ev) => {
  const display = warningsText.style.display === 'block'
  warningsText.style.display = display ? 'none' : 'block'
}